import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import Recipe from '../recipes/models/Recipe';
import ICreateCollectionResponse from './models/ICreateCollectionResponse';
import IRequestAllResponse from './models/IRequestAllResponse';
import IRequestCollectionResponse from './models/IRequestCollectionResponse';
import IRecipeSnippet from './models/IRecipeSnippet';
import IUpdateCollectionResponse from './models/IUpdateCollectionResponse';
import INotification from '../notifications/models/INotification';

export default class TertiaryCollectionEffect {
    static async requestNewCollection(data: any) {
        const endpoint = environment.api.tertiaryCollections.replace('/:endpoint', '');

        return EffectUtility.postToModel(ICreateCollectionResponse, endpoint, data);
    }

    static async requestCollectionUpdate(data: any) {
        const endpoint = environment.api.tertiaryCollections.replace('/:endpoint', '');

        return EffectUtility.putToModel(IUpdateCollectionResponse, endpoint, data);
    }

    static async requestAdditionalRecipeInfo(data: { recipe_id: string; organization_id: string }) {
        const endpoint = environment.api.recipeSearch.replace(':endpoint', 'recipe-snippet');

        return EffectUtility.getToModel(IRecipeSnippet, endpoint, data);
    }

    static async requestCollection(data: { id: string }) {
        const endpoint = environment.api.tertiaryCollections.replace('/:endpoint', '');

        return EffectUtility.getToModel(IRequestCollectionResponse, endpoint, data);
    }

    static async searchCollections(data: { name: string }) {
        const endpoint = environment.api.tertiaryCollections.replace(':endpoint', 'search');

        return EffectUtility.getToModel(IRequestAllResponse, endpoint, data);
    }

    static async requestPCExport(data: string[]) {
        const endpoint = environment.api.pcApi + '/export-to-unata';

        return EffectUtility.postToModel(INotification, endpoint, data);
    }

    static async requestRocheExport(data: string[]) {
        const endpoint = environment.api.rocheProjection + '/export-csv';

        return EffectUtility.postToModel(INotification, endpoint, data);
    }

    static async requestDoleExport(data: string[]) {
        const endpoint = environment.api.dole.replace(':endpoint', 'export');

        return EffectUtility.postToModel(INotification, endpoint, data);
    }
}
